<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2"></div>
            <div class="mx-2 mb-2">
              <div class="row mb-2">
                <div class="col-12 col-md-6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="searchQuery"
                        @change="getUsers"
                        class="d-inline-block mr-1"
                        placeholder="Поиск..."
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button :to="{name: 'user-create'}" variant="primary">
                      <span class="text-nowrap">Добавить пользователя</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ users.from }} по {{ users.to }} из {{ users.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="users.total"
                      :per-page="users.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th rowspan="2">#</th>
                  <th rowspan="2">Дата</th>
                  <th rowspan="2">Статус</th>
                  <th colspan="2" class="text-center">Баланс</th>
                  <th rowspan="2">Имя Фамилия</th>
                  <th rowspan="2">Роли</th>
                  <th rowspan="2">Посл. вход</th>
                  <th rowspan="2">Действия</th>
                </tr>
                <tr>
                  <th>Клиент</th>
                  <th>Поставщик</th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="user in users.data" :key="user.id" >
                  <td>
                    <router-link :to="{ name: 'user-details', params: { id: user.id }}" class="font-weight-bold">
                      #{{ user.id }}
                    </router-link>
                  </td>
                  <td>{{ $formatters.formattedDate(user.created_at) }}</td>
                  <td :class="user.is_active ? 'positive' : 'negative'">
                    {{ user.is_active ? 'Активный' : 'Неактивный' }}
                  </td>
                  <td>
                    <div class="text-nowrap" v-for="(balance, index) in user.client_balance" :key="index">
                      <span v-if="balance.amount !== 0" :class="['amount', balance.amount > 0 ? 'positive' : 'negative']">
                        {{ balance.amount }}
                      </span> <span v-if="balance.amount !== 0" class="currency">{{ balance.currency_name }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="text-nowrap" v-for="(balance, index) in user.supplier_balance" :key="index">
                      <span :class="['amount', balance.amount > 0 ? 'positive' : 'negative']" v-if="balance.amount !== 0">
                        {{ balance.amount }}
                      </span>
                      <span class="currency" v-if="balance.amount !== 0">{{ balance.currency_name }}</span>
                    </div>
                  </td>
                  <td :class="user.surname && user.surname.length > 25 && 'font-small-2'">
                    <span :class="user.surname && user.surname.length > 20 && 'text-dashed'">
                     {{ user.name }} {{ user.surname && user.surname.length > 25 ? user.surname.substring(0, 25) + '...' : user.surname }}
                    </span>
                  </td>
                  <td>{{ user.roles.join(', ') }}</td>
                  <td>{{ user.last_visit | formattedDate }}</td>
                  <td>
                    <button class="btn btn-danger" @click="deleteUser(user.id)">
                      Удалить
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ users.from }} по {{ users.to }} из {{ users.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="users.total"
                      :per-page="users.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BPagination,
} from 'bootstrap-vue';

export default {
  name: 'Users',
  components: {
    BFormInput, BButton, BPagination,
  },
  data() {
    return {
      currentPage: 1,
      users: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 25,
      },
      searchQuery: null,
      loading: true,
    };
  },
  watch: {
    async currentPage(newValue) {
      this.users.current_page = newValue;
      await this.getUsers();
    },
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      this.users = (await this.$api.users.paginated({
        ...(this.users.current_page > 1 && { page: this.users.current_page }),
        ...(this.searchQuery && { search: this.searchQuery }),
        limit: 25,
      })).data;
      this.loading = false;
    },
    async deleteUser(id) {
      this.$swal({
        title: 'Вы уверены?',
        text: 'Вы не сможете это вернуть назад!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, подтверждаю!',
        cancelButtonText: 'Нет, отмена!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$api.users.delete(id);
          await this.getUsers();
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Пользователь был успешно удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

::v-deep .v-select {
  width: 220px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}

.row-green {
  background-color: #e6ffe6;
}

.row-warning {
  background-color: #fff3cd;
}
span.amount {
  font-size: 18px;
}
.text-dashed {
  border-bottom: 1px dotted #6c6c6c;
  cursor: pointer;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
